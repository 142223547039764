<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      label="网格管理员"
      :value="name"
      placeholder="选择网格管理员"
      :rules="[{ required: true, message: '请选择网格管理员' }]"
      is-link
      @click="open"
    />
    <hr
      style="width: 91.161%; size:50px; color: #9f9f9f; text-align: center; margin: 0;border: 0"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="网格管理员"
        show-toolbar
        :columns="cellPersonOptions"
        value-key="text"
        :colunms-field-names="{ text: 'label' }"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  import { getCellPerson } from '@/api/base/base'

  export default {
    name: 'CellPerson',
    props: {
      cellId: {
        type: Number,
        default: null,
      },
      personId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        value: {},
        name: '',
        showPicker: false,
        cellPersonOptions: [],
      }
    },
    created() {
      if (this.cellId != null) this.getCellPersonOptions()
    },
    methods: {
      //选值触发，回调方法返回选项value值
      onConfirm(value) {
        this.name = value.text
        this.value = value.value
        this.$emit('cellPersonChange', value.value)
        this.showPicker = false
      },

      //点击触发，展开选择器
      open() {
        if (this.cellId != null) this.getCellPersonOptions()
        this.showPicker = true
      },

      //获取网格管理员选项
      async getCellPersonOptions() {
        const { data } = await getCellPerson({ Cell_ID: this.cellId })
        this.cellPersonOptions = data
        if (this.personId != null) this.bindOption()
      },

      //回绑方法
      bindOption() {
        this.cellPersonOptions.forEach((option) => {
          if (option.value == this.personId) {
            this.name = option.text
            this.value = { text: option.text, value: this.personId }
          }
        })
      },

      //清空选择器
      reset() {
        this.name = ''
        this.value = {}
      },

      //刷新
      async render(cellId, personID) {
        const { data } = await getCellPerson({ Cell_ID: cellId })
        this.cellPersonOptions = data
        this.cellPersonOptions.forEach((option) => {
          if (option.value == personID) {
            this.name = option.text
            this.value = { text: option.text, value: this.teamId }
          }
        })
      },
    },
  }
</script>

<style scoped></style>
