<template>
  <div>
    <van-field
      readonly
      clickable
      name="picker"
      label="所属中队"
      :value="name"
      placeholder="选择所属中队"
      :rules="[{ required: true, message: '请选择所属中队' }]"
      is-link
      @click="showPicker = true"
    />
    <hr
      style="width: 91.161%; size:50px; color: #9f9f9f; text-align: center; margin: 0;border: 0"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="所属中队"
        show-toolbar
        :columns="teamOptions"
        value-key="text"
        :colunms-field-names="{ text: 'label' }"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  import { getTeam } from '@/api/base/base'

  export default {
    name: 'Team',
    props: {
      teamId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        value: {},
        name: '',
        showPicker: false,
        teamOptions: [],
      }
    },
    created() {
      this.getTeamOptions()
    },
    methods: {
      //选值触发，内含回调方法返回选项value值
      onConfirm(value) {
        this.name = value.text
        this.value = value.value
        this.$emit('teamChange', value.value)
        this.showPicker = false
      },

      //获取中队选项
      async getTeamOptions() {
        const { data } = await getTeam()
        this.teamOptions = data

        //有值则回绑
        if (this.teamId != null) this.bindOption()
      },

      //回绑方法
      bindOption() {
        this.teamOptions.forEach((option) => {
          if (option.value == this.teamId) {
            this.name = option.text
            this.value = { text: option.text, value: this.teamId }
          }
        })
      },

      //清空中队选择器
      reset() {
        this.name = ''
        this.value = {}
      },

      //刷新
      render(value) {
        this.teamOptions.forEach((option) => {
          if (option.value == value) {
            this.name = option.text
            this.value = { text: option.text, value: this.teamId }
          }
        })
      },
    },
  }
</script>

<style scoped></style>
