<template>
  <div style="background: white">
    <van-cell-group>
      <van-cell title="扫码结果" :label="formData.Street_Name" size="large" />
    </van-cell-group>
    <van-cell v-show="queryForm.Type == 1" title="搜索" size="large" />
    <van-row v-show="queryForm.Type == 1">
      <van-col :span="21">
        <van-search
          v-model="queryForm.keyWord"
          placeholder="请输入店铺全称/或者信用代码/或者注册号"
          @cancel="onCancel"
        />
      </van-col>
      <van-col v-if="queryForm.keyWord !== ''" :span="3">
        <van-icon
          name="search"
          style="margin-top: 0.26667rem"
          @click="search"
        />
      </van-col>
      <van-col v-else :span="3">
        <van-icon name="scan" style="margin-top: 0.26667rem" @click="scanQR" />
      </van-col>
    </van-row>
    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      店铺信息
    </van-divider>
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="formData.Shop_Name"
          label="店铺名称"
          placeholder="请填写店铺名称"
          :rules="[{ required: true, message: '请填写店铺名称' }]"
        />
        <van-field
          v-model="formData.Shop_Sign"
          label="店招"
          placeholder="请填写店招"
        />
        <van-field
          v-model="formData.Uniscid"
          label="社会信用代码"
          placeholder="请填写社会信用代码"
          :rules="[{ required: true, message: '请填写社会信用代码' }]"
        />
        <van-field
          v-model="formData.Regist_Number"
          label="注册号"
          placeholder="请填写注册号"
          :rules="[{ required: true, message: '请填写注册号' }]"
        />
        <van-field
          v-model="formData.Company_Type"
          label="公司类型"
          placeholder="请填写公司类型"
          :rules="[{ required: true, message: '请填写公司类型' }]"
        />
        <van-field
          v-model="formData.Shop_Address"
          label="注册地址"
          placeholder="请填写注册地址"
          :rules="[{ required: true, message: '请填写注册地址' }]"
        />
        <van-field
          label="注册时间"
          clickable
          :value="formData.Opfrom"
          readonly="readonly"
          placeholder="点击选择注册时间"
          :rules="[{ required: true, message: '请选择时间' }]"
          @click="showPickerOpfrom = true"
        />
        <van-popup v-model="showPickerOpfrom" position="bottom">
          <van-datetime-picker
            v-model="dateOpfrom"
            type="date"
            :min-date="minDate"
            @confirm="onConfirmOpfrom"
            @cancel="showPickerOpfrom = false"
          />
        </van-popup>

        <van-field
          label="营业时间"
          clickable
          :value="formData.Estdate"
          readonly="readonly"
          placeholder="点击选择营业时间"
          :rules="[{ required: true, message: '请选择时间' }]"
          @click="showPickerEstdate = true"
        />
        <van-popup v-model="showPickerEstdate" position="bottom">
          <van-datetime-picker
            v-model="dateEstdate"
            type="date"
            :min-date="minDate"
            @confirm="onConfirmEstdate"
            @cancel="showPickerEstdate = false"
          />
        </van-popup>
        <van-field
          v-model="formData.Legal_Name"
          label="法定代表人"
          placeholder="请填写法定代表人"
          :rules="[{ required: true, message: '请填写法定代表人' }]"
        />
        <van-field
          v-model="formData.Shop_Tel"
          label="联系方式"
          placeholder="请填写联系方式"
        />
        <van-field
          v-model="formData.ID_Card"
          label="身份证号"
          placeholder="请填写身份证号"
        />
        <team ref="team" :team-id="formData.Team_ID" @teamChange="teamChange" />
        <team-cell
          ref="cell"
          :team-id="formData.Team_ID"
          :cell-id="formData.Cell_ID"
          @teamCellChange="teamCellChange"
        />
        <cell-person
          ref="person"
          :cell-id="formData.Cell_ID"
          :person-id="formData.Person_ID"
          @cellPersonChange="cellPersonChange"
        />
        <shop-type
          ref="shopType"
          :shop-type="formData.Shop_Type"
          @shopTypeChange="shopTypeChange"
        />
        <van-field
          readonly
          clickable
          name="picker"
          label="门前三包"
          :value="outDoorName"
          placeholder="选择门前三包"
          :rules="[{ required: true, message: '请选择门前三包' }]"
          @click="showOutDoor = true"
        />
        <van-popup v-model="showOutDoor" round position="bottom">
          <van-picker
            title="门前三包"
            show-toolbar
            :columns="outDoorOptions"
            value-key="text"
            :colunms-field-names="{ text: 'label' }"
            @confirm="onConfirmOutDoor"
          />
        </van-popup>
        <van-nav-bar title="照片" />
        <div>
          <van-field readonly label="近景照片" />
          <img
            v-for="(item, i) in closeShots"
            :key="i"
            :src="item.Url"
            style="width: 80px;height:80px;display: inline-block;padding: 2px"
            @click="closeShotsPreview"
          />
        </div>
        <div>
          <van-field readonly label="远景照片" />
          <img
            v-for="(item, i) in distantShots"
            :key="i"
            :src="item.Url"
            style="width: 80px;height:80px;display: inline-block;padding: 2px"
            @click="distantShotsPreview"
          />
        </div>
        <van-field v-if="queryForm.Type != 3" readonly label="近景照片" />
        <div v-if="queryForm.Type != 3" style="padding: 10px;background: white">
          <upload-file :limit="2" @fetch-data="fetchCloseShots" />
          <label style="font-size: 15px;color: red;">
            拍摄照片请尽可能露出门牌
          </label>
        </div>
        <van-field v-if="queryForm.Type != 3" readonly label="远景照片" />
        <div v-if="queryForm.Type != 3" style="padding: 10px;background: white">
          <upload-file :limit="2" @fetch-data="fetchDistantShots" />
          <label style="font-size: 15px;color: red;">
            拍摄照片请尽可能露出门牌
          </label>
        </div>
        <van-row style="padding: 5px">
          <van-col
            v-if="queryForm.Type == 1"
            :span="12"
            style="text-align: right;padding-right: 5px"
          >
            <van-button type="info" icon="guide-o" native-type="submit">
              绑定
            </van-button>
          </van-col>
          <van-col
            v-if="queryForm.Type == 2"
            :span="12"
            style="text-align: right;padding-right: 5px"
          >
            <van-button type="info" icon="guide-o" native-type="submit">
              保存
            </van-button>
          </van-col>
          <van-col
            v-if="queryForm.Type == 3"
            :span="12"
            style="text-align: right;padding-right: 10px"
          >
            <van-button
              type="danger"
              icon="cross"
              native-type="button"
              @click="unBind"
            >
              解绑
            </van-button>
          </van-col>
          <van-col :span="12" style="text-align: left;padding-left: 10px">
            <van-button
              type="danger"
              icon="cross"
              native-type="button"
              @click="close"
            >
              关闭
            </van-button>
          </van-col>
        </van-row>
      </van-form>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import uploadFile from '@/components/UploadFile'
  import Team from '@/components/Team'
  import TeamCell from '@/components/TeamCell'
  import CellPerson from '@/components/CellPerson'
  import ShopType from '@/components/ShopType'
  import { Dialog, ImagePreview, Toast } from 'vant'
  import {
    getShopInfo,
    bindShop,
    unBindShop,
    getBindShop,
    updateShop,
  } from '@/api/workBench/shop/shop'
  import moment from 'moment'
  import { Emas } from '@/assets/js/Emas'
  import { systemUrl } from '@/config'

  export default {
    name: 'BindShop',
    components: { Team, TeamCell, uploadFile, CellPerson, ShopType },
    data() {
      return {
        minDate: new Date(1990, 0, 1),

        showOutDoor: false,
        outDoorName: '优秀',

        showPickerOpfrom: false,
        dateOpfrom: new Date(), //成立日期

        showPickerEstdate: false,
        dateEstdate: new Date(), //营业日期
        closeShotsArr: [], //近景照片
        distantShotsArr: [], //远景照片
        closeShotsList: [],
        distantShotsList: [],
        closeShots: '', //近景照片
        distantShots: '', //远景照片
        queryForm: {
          keyWord: '',
          Shop_ID: 0,
          Type: 1, //1绑定 2修改 3解绑
          User_ID: '',
        },

        formData: {
          Shop_ID: '',
          Shop_Name: '',
          Street_ID: '',
          Street_Name: '',
          Shop_Sign: '',
          Uniscid: '',
          Regist_Number: '',
          Company_Type: '',
          Shop_Address: '',
          Opfrom: '',
          Estdate: '',
          Legal_Name: '',
          Shop_Tel: '',
          Mobile: '',
          ID_Card: '',
          Team_ID: 0,
          Cell_ID: 0,
          Person_ID: 0,
          Shop_Type: 0,
          OutDoor: '优秀',
          Business: '',
          Type: 1,
          closeShots: '', //近景照片
          distantShots: '', //远景照片
        },

        outDoorOptions: [
          {
            text: '优秀',
            value: '优秀',
          },
          {
            text: '良好',
            value: '良好',
          },
          {
            text: '一般',
            value: '一般',
          },
        ],
      }
    },
    created() {
      this.queryForm.Type = Base64.decode(this.$route.query.type)
      this.queryForm.Shop_ID = Base64.decode(this.$route.query.shopID)
      this.formData.Street_ID = this.$route.query.street_ID
      this.formData.Street_Name = Base64.decode(this.$route.query.streetName)
      if (this.queryForm.Type != 1) {
        this.getShopInfo()
      }
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      this.queryForm.User_ID = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'bindShop',
        '城管-店铺绑定页面',
        'zzdcg.yy.gov.cn/bindShop'
      )
    },
    methods: {
      //扫码二维码
      scanQR() {
        window.dd
          .scan({
            type: 'qr',
          })
          .then((res) => {
            let result = res.text
            if (result.substring(0, 4) === 'http') {
              this.queryForm.keyWord = result.split('=')[1]
            }
            if (result.split(';').length > 1) {
              this.queryForm.keyWord = result.split(';')[0].split(':')[1]
            }
          })
          .catch((err) => {
            Toast('扫码失败，请确认二维码是否正确')
          })

        /*   let result =
          'http://www.gsxt.gov.cn/index.html?uniscid=9133028176853339X4'
        if (result.substring(0, 4) === 'http') {
          this.queryForm.keyWord = result.split('=')[1]
        }
        if (result.split(';').length > 1) {
          this.queryForm.keyWord = result.split(';')[0].split(':')[1]
        }*/
      },

      //点击按钮修改
      search() {
        if (this.queryForm.Type == 1 && this.queryForm.keyWord == '') {
          Toast('请输入店铺全称/或者信用代码/或者注册号')
        } else {
          this.getShopInfo()
        }
      },

      async getShopInfo() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        console.log(this.queryForm)
        const { data } = await getShopInfo(this.queryForm)
        console.log(data)
        this.dateOpfrom =
          data.Opfrom !== null && data.Opfrom !== ''
            ? new Date(data.Opfrom)
            : null
        this.dateEstdate =
          data.Estdate !== null && data.Estdate !== ''
            ? new Date(data.Estdate)
            : null
        data.CloseShotsList = this.makeFileUrl(data.CloseShotsList)
        data.DistantShotsList = this.makeFileUrl(data.DistantShotsList)
        this.closeShots = data.CloseShotsList
        this.distantShots = data.DistantShotsList
        this.formData.Shop_ID = data.Shop_ID
        this.formData.Shop_Name = data.Shop_Name
        this.formData.Shop_Sign = data.Shop_Sign
        this.formData.Uniscid = data.Uniscid
        this.formData.Regist_Number = data.Regist_Number
        this.formData.Company_Type = data.Company_Type
        this.formData.Shop_Address = data.Shop_Address
        this.formData.Opfrom =
          data.Opfrom !== null && data.Opfrom !== ''
            ? moment(data.Opfrom).format('yyyy-MM-DD')
            : null
        this.formData.Estdate =
          data.Estdate !== null && data.Estdate !== ''
            ? moment(data.Estdate).format('yyyy-MM-DD')
            : null
        this.formData.Legal_Name = data.Legal_Name
        this.formData.Shop_Tel = data.Shop_Tel
        this.formData.Mobile = data.Mobile
        this.formData.ID_Card = data.ID_Card
        this.formData.Shop_Type = data.Shop_Type
        this.formData.Business = data.Business
        this.formData.OutDoor = data.OutDoor
        this.outDoorName = data.OutDoor

        let Type = data.Type
        //0无店铺信息 1 IRS店铺信息 其余数据库店铺信息
        switch (Type) {
          case 0:
            Dialog.confirm({
              title: '提示',
              message:
                '未在工商数据中找到该店铺，请问您需要手动添加吗?点击确定后进行店铺新增，点击取消重新搜索。',
            })
              .then(async () => {
                this.queryForm.keyWord = ''
                await this.reset()
              })
              .catch(() => {})
            this.formData.Type = 3
            break
          case 1:
            let userInfo = JSON.parse(store.getters['user/userInfo'])
            let teamInfo = JSON.parse(store.getters['user/teamInfo'])
            this.formData.User_ID = Number(userInfo.User_ID)
            this.formData.Team_ID = Number(teamInfo.Team_ID)
            this.formData.Cell_ID = Number(teamInfo.Cell_ID)
            this.formData.Person_ID = Number(userInfo.User_ID)
            this.formData.Type = 1

            break
          default:
            this.formData.Team_ID = data.Team_ID
            this.formData.Cell_ID = data.Cell_ID
            this.formData.Person_ID = data.Person_ID
            this.formData.Type = 2
            break
        }
        console.log(data)
        await this.render()
        await Toast.clear()
      },

      //提交
      async onSubmit() {
        //判断当前店铺是否被绑定
        //Type 1.绑定 2.保存 3，解绑
        if (this.queryForm.Type == 1) {
          let streetArr = await this.getBindShop()
          if (streetArr.length > 0) {
            let msg = this.makeMessage(streetArr)
            Dialog.confirm({
              title: '标题',
              message: msg,
              confirmButtonText: '确认无误提交',
              cancelButtonText: '重新搜索',
              messageAlign: 'left',
            })
              .then(() => {
                this.save()
              })
              .catch(() => {
                this.onCancel()
              })
          } else {
            await this.save()
          }
        } else {
          await this.save()
        }
      },

      //绑定店铺
      async save() {
        console.log(this.queryForm.Type)
        if (this.closeShotsArr.length > 0) {
          this.formData.closeShots = this.closeShotsArr.join('|')
        }
        if (this.distantShotsArr.length > 0) {
          this.formData.distantShots = this.distantShotsArr.join('|')
        }

        //Type 1.绑定 2.保存 3，解绑
        switch (this.queryForm.Type) {
          case '1':
            await this.bindShop()
            break
          case '2':
            await this.updateShop()
            break
        }
        await this.$router.push({
          path: '/scan',
          query: { street_ID: this.formData.Street_ID },
        })
      },

      //绑定
      async bindShop() {
        Toast.loading({
          duration: 5000, // 持续展示 toast
          message: '正在绑定中...',
          forbidClick: true,
        })
        const { success, data, msg } = await bindShop(this.formData)
        await Toast.clear()
        await Toast(msg)
      },

      //更新
      async updateShop() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在更新中...',
          forbidClick: true,
        })
        const { data, msg } = await updateShop(this.formData)
        await Toast.clear()
        await Toast(msg)
      },

      //解绑店铺
      unBind() {
        Dialog.confirm({
          title: '提示',
          message: '请确定是否需要解绑当前店铺？',
        })
          .then(async () => {
            const { success, msg } = await unBindShop({
              Street_ID: this.formData.Street_ID,
            })
            Toast(msg)
            if (success) {
              await this.$router.push({
                path: '/scan',
                query: { street_ID: this.formData.Street_ID },
              })
            }
          })
          .catch(() => {})
      },

      //判断当前店铺是否被其余地址绑定
      async getBindShop() {
        const { data } = await getBindShop({
          Street_ID: this.formData.Street_ID,
          Uniscid: this.formData.Uniscid,
        })
        return data
      },

      //组装提示消息
      makeMessage(streetArr) {
        let msg = '当前店铺已被:' + '\n'
        msg += streetArr.join('\n')
        msg += '<br/>'
        msg += '\n' + '绑定请您核对无误后提交！'
        return msg
      },

      //获取门前三包
      onConfirmOutDoor(val) {
        this.outDoorName = val.text
        this.formData.OutDoor = val.value
        this.showOutDoor = false
      },

      //注册时间
      onConfirmOpfrom() {
        this.formData.Opfrom = moment(this.dateOpfrom).format('yyyy-MM-DD')
        this.showPickerOpfrom = false
      },

      //营业期限
      onConfirmEstdate() {
        this.formData.Estdate = moment(this.dateEstdate).format('yyyy-MM-DD')
        this.showPickerEstdate = false
      },

      //中队选择
      teamChange(val) {
        this.$refs['cell'].reset()
        this.$refs['person'].reset()
        this.formData.Team_ID = val
      },

      //网格选择
      teamCellChange(val) {
        this.$refs['person'].reset()
        this.formData.personID = 0
        this.formData.Cell_ID = val
      },

      //人员选择
      cellPersonChange(val) {
        this.formData.Person_ID = val
      },

      //店铺选择
      shopTypeChange(val) {
        this.formData.Shop_Type = val
      },

      //回绑定
      async render() {
        await this.$refs['team'].render(this.formData.Team_ID)
        await this.$refs['cell'].render(
          this.formData.Team_ID,
          this.formData.Cell_ID
        )
        await this.$refs['person'].render(
          this.formData.Cell_ID,
          this.formData.Person_ID
        )
        await this.$refs['shopType'].render(this.formData.Shop_Type)
      },

      //重置
      async reset() {
        this.$refs['team'].reset()
        this.$refs['cell'].reset()
        this.$refs['person'].reset()
        this.$refs['shopType'].reset()
      },

      //查询取消
      onCancel() {
        this.queryForm.keyWord = ''
      },

      //关闭
      close() {
        Dialog.confirm({
          title: '提示',
          message: '请确定是否关闭？',
        })
          .then(() => {
            this.$router.go(-1)
          })
          .catch(() => {})
      },
      //照片上传回调方法
      fetchCloseShots(pathList) {
        this.closeShotsArr = pathList
      },
      fetchDistantShots(pathList) {
        this.distantShotsArr = pathList
      },
      //照片预览
      closeShotsPreview() {
        ImagePreview({
          images: this.closeShotsList,
        })
      },
      distantShotsPreview() {
        ImagePreview({
          images: this.distantShotsList,
        })
      },
      //路径拼接
      makeFileUrl(fileList) {
        if (fileList != null) {
          if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
              fileList[i].Url = systemUrl + fileList[i].Url
              switch (fileList[i].Type) {
                case 1:
                  this.closeShotsList.push(fileList[i].Url)
                  break
                case 2:
                  this.distantShotsList.push(fileList[i].Url)
                  break
              }
            }
          }
        }

        return fileList
      },
    },
  }
</script>
<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    /*display: inline-block;*/
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
